import React, { useEffect, useRef } from 'react';
import { Buk, FolkekirkenIcon, KenyaMapIcon, OleKirkLogo } from '../components/svgs';
import { Text } from '../components';
import Layout from '../components/layout';
import Meta from '../components/meta';
import { isBrowser } from '../helpers/isBrowser';
import { useStoryblok } from '../lib/storyblok';

const PrintPage = ({ pageContext, location}) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);

	const imageRef = useRef(null);

	const print = () => {
		if(isBrowser()) {
			if(content?.illustration?.filename.length > 0) {
				setTimeout(() => {
					window.print();
				}, 400);
			}
		}
	};

	const content = story.content.body.find(element => element.component === 'print-section');

	useEffect(() => {
		if(isBrowser()) {
			if(imageRef !== null) {
				imageRef.current?.addEventListener('load', print);
			}
			return imageRef.current?.removeEventListener('load', print);
		}
	});

	return (
		<Layout>
			<Meta />
			<div className="flex flex-col justify-center h-full print">
				<div className="flex mx-auto mt-20 mb-20 w-half">
					<Buk />
					<FolkekirkenIcon width="75px" className="ml-16"/>
					<OleKirkLogo className="ml-16" fill="#000" width="100px"/>
				</div>
				<div className="print--content">
					<div className="print--content__image">
						<img ref={imageRef} onLoad={print} src={content?.illustration?.filename || ''} className="mx-auto blok"/>
					</div>
					<div className="flex flex-col items-center justify-center max-w-lg mx-auto text-center">
						<Text type="country" className="my-16">{content?.image_title || ''}</Text>
						<Text type="answer_indicator" className="mb-16 text-black" >
							{content?.image_text || ''}
						</Text>
						<KenyaMapIcon width="16%"/>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default PrintPage;
